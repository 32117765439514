import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import ReactMarkdown from 'react-markdown';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import CustomButton from '../../CustomButton';

import './aboutOurTeam.scss';

const AboutOurTeam = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <Fade delay={1500}>
        <div className="team_main_wrapper">
          <div className="team_left_container">
            <h3 className="team_title">{props.data.title}</h3>
            <ReactMarkdown className="team_description">
              {props.data.description}
            </ReactMarkdown>
            <p className="team_text">{props.data.text}</p>
            <CustomLink
              link={{
                url: `${localizePath({
                  ...pageContext,
                  isPreview: false,
                  slug:
                    props.pageContext.locale === 'bs'
                      ? `${props.data.slug}`.slice(0)
                      : props.data.slug,
                })}`,
              }}
              to={`/${props.data.slug}`}
            >
              <CustomButton buttonText={props.data.contact} />
            </CustomLink>
          </div>
          <div className="team_right_container">
            <img className="team_image" src={props.data.image.url} alt="" />
          </div>
        </div>
      </Fade>
    </div>
  );
};
AboutOurTeam.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default AboutOurTeam;
