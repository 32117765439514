import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import ReactMarkdown from 'react-markdown';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import CustomButton from '../../CustomButton';
import './solutionsAboutUs.scss';

const AboutSolutions = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <div className="main_solutions_wrapper">
        <div className="solutions_container">
          <Fade left duration={1500}>
            <div className="solutions_left_container">
              <h3 className="solutions_title">{props.data.title}</h3>
              <p className="solutions_description">{props.data.description}</p>
              <ReactMarkdown className="solutions_content">
                {props.data.content}
              </ReactMarkdown>
              <CustomLink
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug:
                      props.pageContext.locale === 'bs'
                        ? `${props.data.slug}`.slice(0)
                        : props.data.slug,
                  })}`,
                }}
                to={`/${props.data.slug}`}
              >
                <CustomButton buttonText={props.data.contact} />
              </CustomLink>
            </div>
          </Fade>
          <div className="solutions_right_container">
            <Fade right duration={1500}>
              <img
                className="solutions_image"
                src={props.data.image.url}
                alt=""
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
AboutSolutions.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default AboutSolutions;
