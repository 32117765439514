import React from 'react';
import PropTypes from 'prop-types';
import { Flip } from 'react-reveal';

import './ourValues.scss';

const OurValues = (props) => (
  <div>
    <div className="values_main_wrapper">
      <h3 className="values_title">{props.data.title}</h3>
      <p className="values_description">{props.data.description}</p>
      <div className="values_container">
        {props.data.valuesContainer.map((item) => (
          <div className="values_inner_container" key={item.id}>
            <div className="values_inner_content">
              <Flip left cascade>
                <img className="values_icon" src={item.icon.url} alt="" />
              </Flip>
            </div>
            <Flip left cascade>
              <p className="values_title">{item.title}</p>
            </Flip>
          </div>
        ))}
      </div>
    </div>
  </div>
);
OurValues.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    valuesContainer: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default OurValues;
