import { graphql, useStaticQuery } from 'gatsby';

const useTeams = () => {
  const {
    allStrapiTeams: { nodes: teams },
  } = useStaticQuery(graphql`
    {
      allStrapiTeams(sort: { order: ASC, fields: strapiId }) {
        nodes {
          name
          position
          locale
          image {
            url
          }
        }
      }
    }
  `);
  return teams;
};

export default useTeams;
