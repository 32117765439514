import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import useTeams from '../../../queries/teamQueries';
import './team.scss';

const OurTeam = (props) => {
  const teamsData = useTeams();
  const langTeamsData = teamsData.filter(
    (item) => item.locale === props.locale,
  );
  return (
    <div>
      <div className="team_body_wrapper">
        <div className="team_title_container">
          <h3 className="team_title">{props.data.title}</h3>
          <p className="team_description">{props.data.description}</p>
          <p className="team_content">{props.data.content}</p>
        </div>
        <div className="team_card_wrapper">
          <Fade>
            {langTeamsData.map((team) => (
              <div
                className="team_container"
                key={team.id}
                style={{ backgroundImage: `url(${team.image.url})` }}
              >
                <div className="team_wrapper">
                  <p className="team_text team_text_1">{team.name}</p>
                  <p className="team_text team_text_2">{team.position}</p>
                </div>
              </div>
            ))}
          </Fade>
        </div>
      </div>
    </div>
  );
};
OurTeam.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default OurTeam;
