import React from 'react';
import PropTypes from 'prop-types';
import { BiRightArrowAlt } from 'react-icons/bi';

import './button.scss';

const CustomButton = ({ buttonText }) => (
  <div>
    <button type="button" className="management_button">
      {buttonText}
      <span className="management_arrow">
        <BiRightArrowAlt />
      </span>
    </button>
  </div>
);

const { string } = PropTypes;
CustomButton.propTypes = {
  buttonText: string.isRequired,
};

export default CustomButton;
