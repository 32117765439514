import React, { useState } from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';

import './contactPage.scss';

const ContactPage = (props) => {
  const [submitMobile, setSubmitMobile] = useState(props.data.send);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_6fqqkbo',
      'template_jbh1w8c',
      e.target,
      'kX_Rn2VtCDLSU-7Jy',
    );
    e.target.reset();
  };
  return (
    <div>
      <div className="main_contact_wrapper">
        <div className="contact_info_container">
          <div className="contact_title_container">
            <h2 className="contact_title">{props.data.title}</h2>
            <p className="contact_description">{props.data.description}</p>
          </div>
          <div className="contact_info">
            {props.data.location.map((item) => (
              <div className="single_info" key={item.id}>
                <div className="img_container">
                  <img src={item.icon.url} alt="" />
                </div>
                <p className="single_content">{item.content}</p>
              </div>
            ))}
          </div>
        </div>
        <div
          className="right_contact_wrapper"
          style={{
            backgroundImage: `url(${props.data.image.url})`,
          }}
        >
          <div className="right_title_container">
            <h3 className="right_title">{props.data.formTitle}</h3>
            <h2 className="right_contact">{props.data.contact}</h2>
          </div>
          <form className="right_contact_inner" onSubmit={sendEmail}>
            <input
              className="right_name_mail"
              required
              type="text"
              name="name"
              placeholder={props.data.placeholderName}
            />
            <input
              className="right_name_mail"
              required
              type="email"
              name="email"
              placeholder={props.data.placeholderMail}
            />
            <textarea
              className="right_textarea"
              type="text"
              name="message"
              required
              placeholder={props.data.placeholderMessage}
            />
            <button
              className="button"
              type="submit"
              onClick={() => setSubmitMobile(props.data.sent)}
            >
              {submitMobile}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
ContactPage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    formTitle: PropTypes.string.isRequired,
    placeholderName: PropTypes.string.isRequired,
    placeholderMail: PropTypes.string.isRequired,
    placeholderMessage: PropTypes.string.isRequired,
    send: PropTypes.string.isRequired,
    sent: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    location: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default ContactPage;
