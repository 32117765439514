/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import NextSlideArrow from '../../Arrows/nextSlideArrow';
import PrevSlideArrow from '../../Arrows/prevSlideArrow';
import './solutionTestimonials.scss';

const SolutionTestimonials = (props) => {
  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextSlideArrow />,
    prevArrow: <PrevSlideArrow />,
  };
  return (
    <div>
      <div className="main_testimonial_container">
        <div className="inner_testimonial_wrapper">
          <Slider className="testimonial_slider_container" {...settings}>
            {props.data.testimonialsSlider.map((item) => (
              <div className="slider_wrapper" key={item.id}>
                <img className="slider_image" src={item.image.url} alt="" />
                <p className="slider_text">{item.text}</p>
                <p className="slider_name">{`- ${item.name}`}</p>
              </div>
            ))}
          </Slider>
          <div className="testimonial_content_container">
            <div className="content_wrapper">
              <h3 className="testimonial_title">{props.data.title}</h3>
              <p className="testimonial_description">
                {props.data.description}
              </p>
              <p className="testimonial_text">{props.data.content}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SolutionTestimonials.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    testimonialsSlider: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default SolutionTestimonials;
