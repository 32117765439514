import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import { FiArrowUpRight } from 'react-icons/fi';

import './advisoryStatistics.scss';

const Statistics = (props) => (
  <div className="statistics_wrapper">
    <div className="main_container">
      {props.data.statisticsCard.map((item) => (
        <div className="wrapper" key={item.id}>
          <div className="img_wrapper">
            <img className="main_image" src={item.image.url} alt="" />
          </div>
          <div className="inner_wrapper">
            <p className="number">
              <CountUp start={0} end={item.number} duration={5}>
                {item.number}
              </CountUp>
              <span>
                <FiArrowUpRight />
              </span>
            </p>
            <p className="text">{item.text}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
);
Statistics.propTypes = {
  data: PropTypes.shape({
    statisticsCard: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default Statistics;
