import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';
import { FiArrowUpRight } from 'react-icons/fi';
import ReactMarkdown from 'react-markdown';

import './solutionsHero.scss';

const SolutionsHero = (props) => (
  <div>
    <div className="hero_main_wrapper">
      <div className="hero_left_container">
        <h3 className="hero_title">{props.data.title}</h3>
        <ReactMarkdown className="hero_description">
          {props.data.description}
        </ReactMarkdown>
        <div className="hero_statistic_wrapper">
          <div className="hero_container">
            <div className="hero_number_container">
              <p className="hero_number">
                <CountUp start={0} end={100} duration={3}>
                  {props.data.statistic[0].number}
                </CountUp>
              </p>
              <FiArrowUpRight className="hero_arrow" />
            </div>
            <p className="hero_text">{props.data.statistic[0].text}</p>
          </div>
          <div className="hero_container">
            <div className="hero_number_container">
              <p className="hero_number">
                <CountUp start={0} end={80} duration={3}>
                  {props.data.statistic[1].number}
                </CountUp>
              </p>
              <FiArrowUpRight className="hero_arrow" />
            </div>
            <p className="hero_text">{props.data.statistic[1].text}</p>
          </div>
          <div className="hero_container">
            <div className="hero_number_container">
              <p className="hero_number">
                <CountUp start={0} end={10} duration={3}>
                  {props.data.statistic[2].number}
                </CountUp>
              </p>
              <FiArrowUpRight className="hero_arrow" />
            </div>
            <p className="hero_text">{props.data.statistic[2].text}</p>
          </div>
        </div>
      </div>
      <div className="hero_right_container">
        <img className="hero_image" src={props.data.image.url} alt="" />
      </div>
    </div>
  </div>
);
SolutionsHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    statistic: PropTypes.arrayOf(
      PropTypes.shape({
        number: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
};

export default SolutionsHero;
