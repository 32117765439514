/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Slider from 'react-slick';
import PropTypes from 'prop-types';

import NextArrow from '../../Arrows/nextArrow';
import PrevArrow from '../../Arrows/prevArrow';
import './testimonials.scss';

const Testimonials = (props) => {
  const settings = {
    dots: false,
    arrows: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <div className="background">
      <Slider className="slider" {...settings}>
        {props.data.impressions.map((item) => (
          <div className="main_wrapper" key={item.id}>
            <div className="description_wrapper">
              <h3 className="title">{item.title}</h3>
              <p className="description">{item.description}</p>
              <p className="name">{`- ${item.name}`}</p>
            </div>
            <div className="image_container">
              <img className="image" alt="" src={item.image.url} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
Testimonials.propTypes = {
  data: PropTypes.shape({
    impressions: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default Testimonials;
