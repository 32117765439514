import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BiLeftArrowAlt } from 'react-icons/bi';
import './arrows.scss';

const PrevSlideArrow = (props) => {
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  return (
    <div
      onClick={props.onClick}
      role="presentation"
      className="prev_arrow"
      style={{
        transition: '.5s',
        backgroundColor: isHovering ? '#25332F' : '',
        color: isHovering ? '#fff' : '',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <BiLeftArrowAlt className="left_arrow" />
    </div>
  );
};

PrevSlideArrow.propTypes = {
  onClick: PropTypes.func,
};

PrevSlideArrow.defaultProps = {
  onClick: () => {},
};

export default PrevSlideArrow;
