import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { BsCheckCircleFill } from 'react-icons/bs';
import ReactMarkdown from 'react-markdown';

import CustomButton from '../../CustomButton';
import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';

import './about.scss';

const About = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <div className="main_about_us_wrapper">
        <div className="about_us_title_wrapper">
          <h3 className="about_us_title">{props.data.title}</h3>
          <p className="about_us_description">{props.data.description}</p>
        </div>
        <div className="about_us_content_wrapper">
          <Fade right duration={1500} delay={1000}>
            <div className="about_us_text_container">
              <ReactMarkdown className="about_us_content">
                {props.data.content}
              </ReactMarkdown>
              <div className="about_us_check_text_container">
                {props.data.subContent.map((item) => (
                  <div className="about_us_check_text" key={item.id}>
                    <BsCheckCircleFill className="check" />
                    <p className="about_us_text">{item.subText}</p>
                  </div>
                ))}
              </div>
              <CustomLink
                link={{
                  url: `${localizePath({
                    ...pageContext,
                    isPreview: false,
                    slug:
                      props.pageContext.locale === 'bs'
                        ? `${props.data.slug}`.slice(0)
                        : props.data.slug,
                  })}`,
                }}
                to={`/${props.data.slug}`}
              >
                <CustomButton buttonText={props.data.buttonText} />
              </CustomLink>
            </div>
          </Fade>
          <div className="about_us_image_container">
            <Fade left duration={1500} delay={1000}>
              <img
                className="about_us_image"
                src={props.data.image.url}
                alt=""
              />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
About.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
    subContent: PropTypes.arrayOf(
      PropTypes.shape({
        subText: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default About;
