import React from 'react';
import PropTypes from 'prop-types';
import emailjs from 'emailjs-com';

import './contactForm.scss';

const Contact = (props) => {
  // const [submitMobile, setSubmitMobile] = useState(
  //   props.data.contactForm[0].buttonSend,
  // );
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(
      'service_6fqqkbo',
      'template_jbh1w8c',
      e.target,
      'kX_Rn2VtCDLSU-7Jy',
    );
    e.target.reset();
  };
  return (
    <div>
      <div
        className="contact_wrapper"
        style={{
          backgroundImage: `url(${props.data.contactForm[0].image.url})`,
        }}
      >
        <div className="title_container">
          <h3>{props.data.contactForm[0].title}</h3>
          <h2>{props.data.contactForm[0].contactTitle}</h2>
        </div>
        <form className="contact_inner" onSubmit={sendEmail}>
          <input
            className="name_mail"
            required
            type="text"
            name="name"
            placeholder={props.data.contactForm[0].placeholderName}
          />
          <input
            className="name_mail"
            required
            type="email"
            name="email"
            placeholder={props.data.contactForm[0].placeholderEmail}
          />
          <textarea
            type="text"
            name="message"
            required
            placeholder={props.data.contactForm[0].placeholderMessage}
          />
          {/* <button
            className="button"
            type="submit"
            onClick={() =>
              setSubmitMobile(props.data.contactForm[0].buttonSent)
            }
          >
            {submitMobile}
          </button> */}
        </form>
      </div>
    </div>
  );
};
Contact.propTypes = {
  data: PropTypes.shape({
    contactForm: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        contactTitle: PropTypes.string.isRequired,
        placeholderName: PropTypes.string.isRequired,
        placeholderEmail: PropTypes.string.isRequired,
        placeholderMessage: PropTypes.string.isRequired,
        buttonSend: PropTypes.string.isRequired,
        buttonSent: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default Contact;
