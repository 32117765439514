import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import './ourClients.scss';

const Clients = (props) => (
  <div>
    <div className="main_clients_wrapper">
      <h2 className="clients_title">{props.data.title}</h2>
      <p className="clients_description">{props.data.description}</p>
      <div className="clients_icons_container">
        {props.data.icons.map((item) => (
          <div className="clients_icon_container" key={item.id}>
            <Fade bottom cascade delay={1000}>
              <img className="clients_icons" src={item.icons[0].url} alt="" />
            </Fade>
          </div>
        ))}
      </div>
    </div>
  </div>
);
Clients.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    icons: PropTypes.arrayOf(
      PropTypes.shape({
        icons: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default Clients;
