import React from 'react';
import PropTypes from 'prop-types';

import './heroSection.scss';

const HeroImage = (props) => {
  const heroMobileImage = props.data.heroReusable[0].image[0].url;
  const heroDesktopImage = props.data.heroReusable[1].image[0].url;
  return (
    <div>
      {/* Mobile */}
      {props.data.heroReusable.slice(0, 1).map((item) => (
        <div className="main_container" key={item.id}>
          <div
            className="mobile_container"
            style={{ backgroundImage: `url(${heroMobileImage})`, zIndex: 2 }}
          >
            <div className="mobile_content_container">
              <h1>{item.title}</h1>
              <p>{item.subTitle}</p>
            </div>
          </div>
        </div>
      ))}
      {/* Desktop */}
      {props.data.heroReusable.slice(1, 2).map((item) => (
        <div className="main_container_desktop" key={item.id}>
          <div
            className="desktop_container"
            style={{ backgroundImage: `url(${heroDesktopImage})` }}
          >
            <div className="desktop_content_container">
              <div className="content_wrapper">
                <h1>{item.title}</h1>
                <p>{item.subTitle}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
HeroImage.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
    heroReusable: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default HeroImage;
