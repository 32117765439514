import React from 'react';
import PropTypes from 'prop-types';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import LanguageSelector from '../../LanguageSelector';

import './hero.scss';

const Hero = (props) => {
  const { pageContext } = props;
  return (
    <div>
      {/* mobile */}
      <div className="hero_container mobile">
        <div className="home_navbar">
          <p className="home_logo">Consortio.</p>
          <div className="language_wrapper">
            <LanguageSelector
              className="language_mobile"
              pageContext={pageContext}
            />
          </div>
        </div>
        {props.data.heroSlider.slice(0, 3).map((item) => (
          <div className="home_wrapper" key={item.id}>
            <CustomLink
              link={{
                url: `${localizePath({
                  ...item,
                  ...pageContext,
                  isPreview: false,
                  slug: item.subTitle,
                })}`,
              }}
              to={`/${item.subTitle}`}
            >
              <img src={item.image[0].url} alt="..." />
              <p className="home_title">{item.title}</p>
            </CustomLink>
          </div>
        ))}
      </div>
      {/* desktop */}
      <div className="hero_container desktop">
        <div className="home_navbar">
          <p className="home_logo">Consortio.</p>
          <div className="language_wrapper">
            <LanguageSelector
              className="language_mobile"
              pageContext={pageContext}
            />
          </div>
        </div>
        {props.data.heroSlider.slice(3, 6).map((item) => (
          <div className="home_wrapper" key={item.id}>
            <CustomLink
              link={{
                url: `${localizePath({
                  ...item,
                  ...pageContext,
                  isPreview: false,
                  slug: item.subTitle,
                })}`,
              }}
              to={`/${item.subTitle}`}
            >
              <img src={item.image[0].url} alt="..." />
              <p className="home_title">{item.title}</p>
            </CustomLink>
          </div>
        ))}
      </div>
    </div>
  );
};
Hero.propTypes = {
  data: PropTypes.shape({
    heroSlider: PropTypes.arrayOf(
      PropTypes.shape({
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
        title: PropTypes.string.isRequired,
        subTitle: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  navbar: PropTypes.shape({
    mainLinks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        url: PropTypes.string,
        text: PropTypes.string,
      }),
    ),
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};
export default Hero;
