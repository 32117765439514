/* eslint-disable no-dupe-keys */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/prop-types */
/* eslint-disable operator-linebreak */
import React from 'react';
import PropTypes from 'prop-types';

import Hero from './Sections/HeroSection';
import HeroImage from './Sections/AdvisoryHero';
import Statistics from './Sections/StatisticsAdvisory';
import About from './Sections/AboutUs';
import OurProjects from './Sections/OurProjects';
import Contact from './Sections/AdvisoryContact';
import Testimonials from './Sections/Testimonials';
import SolutionsHero from './Sections/SolutionsHero';
import AboutSolutions from './Sections/SolutionsAboutUs';
import SolutionTestimonials from './Sections/SolutionTestimonials';
import ManagementHero from './Sections/ManagementHero';
import ManagementAboutUs from './Sections/ManagementAboutUs';
import ContactPage from './Sections/ContactPage';
import Clients from './Sections/OurClients';
import OurStory from './Sections/OurStory';
import Services from './Sections/OurServices';
import AboutOurTeam from './Sections/AboutOurTeam';
import OurValues from './Sections/OurValues';
import OurTeam from './Sections/TeamSection';
import Project from './Sections/Project';

const sectionComponents = {
  'sections.hero': Hero,
  'sections.hero-main': HeroImage,
  'sections.statistics-section': Statistics,
  'sections.about-us': About,
  'sections.company-projects': OurProjects,
  'sections.contact': Contact,
  'sections.testimonail': Testimonials,
  'sections.solutions-hero': SolutionsHero,
  'sections.solutions-about-us': AboutSolutions,
  'sections.solution-testimonials': SolutionTestimonials,
  'sections.management-hero': ManagementHero,
  'sections.management-about-us': ManagementAboutUs,
  'sections.contact-page': ContactPage,
  'sections.clients-section': Clients,
  'sections.our-story': OurStory,
  'sections.services': Services,
  'sections.about-our-team': AboutOurTeam,
  'sections.team-section': OurTeam,
  'sections.our-values': OurValues,
  'sections.single-project': Project,
};

const Section = ({ sectionData, locale, pageContext }) => {
  const SectionComponent =
    sectionComponents[sectionData.strapi_component || sectionData.__component];
  if (!SectionComponent) {
    return null;
  }
  return (
    <SectionComponent
      pageContext={pageContext}
      data={sectionData}
      locale={locale}
    />
  );
};

const Sections = (props) => {
  const lang = props.locale;
  return (
    <div className="flex flex-col">
      {props.sections.map((section, i) => (
        <Section
          pageContext={props.pageContext}
          sectionData={section}
          locale={lang}
          key={`${section.strapi_component}${(section.id, i)}`}
        />
      ))}
    </div>
  );
};
Section.propTypes = {
  sectionData: PropTypes.shape({
    strapi_component: PropTypes.string.isRequired,
    __component: PropTypes.string,
  }).isRequired,
};

Sections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ).isRequired,
};

export default Sections;
