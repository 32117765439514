import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import CustomButton from '../../CustomButton';
import './managementHero.scss';

const ManagementHero = (props) => {
  const { pageContext } = props;
  return (
    <div>
      <Fade>
        <div className="management_container">
          <div className="management_title_container">
            <h3 className="management_title">{props.data.title}</h3>
            <p className="management_description">{props.data.description}</p>
            <CustomLink
              link={{
                url: `${localizePath({
                  ...pageContext,
                  isPreview: false,
                  slug:
                    props.pageContext.locale === 'bs'
                      ? `${props.data.slug}`.slice(0)
                      : props.data.slug,
                })}`,
              }}
              to={`/${props.data.slug}`}
            >
              <CustomButton buttonText={props.data.contact} />
            </CustomLink>
          </div>
          <div className="management_image_container">
            <img
              className="management_image"
              src={props.data.image.url}
              alt=""
            />
          </div>
        </div>
      </Fade>
    </div>
  );
};
ManagementHero.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
  }).isRequired,
};

export default ManagementHero;
