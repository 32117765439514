import React from 'react';
import PropTypes from 'prop-types';
import { Flip } from 'react-reveal';

import './services.scss';

const Services = (props) => (
  <div>
    <div className="main_services_wrapper">
      <h3 className="services_title">{props.data.title}</h3>
      <p className="services_description">{props.data.description}</p>
      <div className="services_offer_container">
        {props.data.serviceOffer.map((item) => (
          <div className="services_offer_inner_container" key={item.id}>
            <div className="services_icon_container">
              <Flip left cascade duration={1000}>
                <img
                  className="services_offer_icon"
                  src={item.icon[0].url}
                  alt=""
                />
              </Flip>
            </div>
            <div className="services_offer_inner_content">
              <p className="services_offer_title">{item.title}</p>
              <Flip left cascade duration={1000}>
                <p className="services_offer_text">{item.text}</p>
              </Flip>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    serviceOffer: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        icon: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
};

export default Services;
