/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Sections from '../components/sections';
import SEO from '../components/seo';

const DynamicPage = ({ data, pageContext }) => {
  const { contentSections, metadata, locale } = data.strapiPages;
  const global = data.strapiGlobal;
  return (
    <>
      <SEO seo={metadata} global={global} />
      <Layout global={global} pageContext={pageContext}>
        <Sections
          pageContext={pageContext}
          sections={contentSections}
          globalData={global}
          locale={locale}
        />
      </Layout>
    </>
  );
};

export default DynamicPage;

export const query = graphql`
  fragment GlobalData on StrapiGlobal {
    navbar {
      image {
        url
      }
      mainLinks {
        id
        text
        url
      }
    }
    metadata {
      metaDescription
      id
      metaTitle
    }
    locale
    localizations {
      id
      locale
    }
    footer {
      aboutTitle
      companyName
      contact {
        address
        email
        id
        tel
      }
      contactTitle
      copyright {
        id
        title
      }
      id
      serviceTitle
      services {
        id
        slug
        title
      }
      about {
        id
        slug
        title
      }
    }
    locale
  }

  query DynamicPageQuery($id: String!, $locale: String!) {
    strapiGlobal(locale: { eq: $locale }) {
      ...GlobalData
    }
    strapiPages(id: { eq: $id }) {
      slug
      ShortName
      contentSections
      locale
      metadata {
        metaDescription
        metaTitle
      }
      localizations {
        id
        locale
      }
    }
  }
`;
