import React from 'react';

import useProjects from '../../../queries/projectsQueries';
import '../../ProjectsDetailPage/project.scss';

const Project = () => {
  const projects = useProjects();
  return (
    <div>
      <div>
        <h2>{projects}</h2>
      </div>
    </div>
  );
};
Project.propTypes = {};

export default Project;
