import React from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import ReactMarkdown from 'react-markdown';

import './ourStory.scss';

const OurStory = (props) => (
  <div>
    <div className="our_story_main_wrapper">
      <Fade right duraiton={1000}>
        <div className="our_story_main_container">
          <div className="our_story_left_container">
            <div className="our_story_left_inner">
              <h2 className="our_story_title">{props.data.title}</h2>
              <p className="our_story_description">{props.data.description}</p>
              <ReactMarkdown className="our_story_content">
                {props.data.content}
              </ReactMarkdown>
            </div>
          </div>
          <div className="our_story_right_container">
            <img
              className="our_story_image"
              src={props.data.image.url}
              alt=""
            />
          </div>
        </div>
      </Fade>
    </div>
  </div>
);
OurStory.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OurStory;
