/* eslint-disable no-console */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Zoom } from 'react-reveal';
import { BiRightArrowAlt } from 'react-icons/bi';
import ReactMarkdown from 'react-markdown';

import { localizePath } from '../../../utils/localize';
import CustomLink from '../../CustomLink';
import useProjects from '../../../queries/projectsQueries';
import './ourProjects.scss';

const OurProjects = (props) => {
  const { pageContext } = props;
  const projectsData = useProjects();
  const langProjectsData = projectsData.filter(
    (item) => item.locale === props.locale,
  );
  const filteredProjects = langProjectsData.filter(
    (item) => item.category === props.pageContext.slug,
  );

  const [showAllProjects, setShowAllProjects] = useState(false);
  const projectsToShow = showAllProjects
    ? filteredProjects
    : filteredProjects.slice(0, 6);

  const toggleShowAllProjects = () => {
    setShowAllProjects(!showAllProjects);
  };

  return (
    <div>
      <div className="body_wrapper">
        <div className="projects_title_container">
          <div className="title_container">
            <h3 className="title">{props.data.portfolioTitle}</h3>
            <p className="description">{props.data.title}</p>
          </div>
          <div className="projects_text">
            <ReactMarkdown className="text">
              {props.data.description}
            </ReactMarkdown>
          </div>
        </div>
        <div className="card_wrapper">
          {projectsToShow.map((item) => (
            <Zoom key={item.slug}>
              <div
                className="container"
                style={{ backgroundImage: `url(${item.image?.url})` }}
              >
                <div className="image-overlay">
                  <div className="middle">
                    <p className="text text_1">{item.title}</p>
                    <p className="text text_2">{item.location}</p>
                  </div>
                </div>
                <CustomLink
                  link={{
                    url: `${localizePath({
                      ...pageContext,
                      isPreview: false,
                      slug:
                        props.pageContext.locale === 'bs'
                          ? `${item.slug}`.slice(0)
                          : item.slug,
                    })}`,
                  }}
                  to={`/${item.slug}`}
                >
                  <button type="button" className="go_to_projects">
                    {props.data.goToProject}
                  </button>
                </CustomLink>
              </div>
            </Zoom>
          ))}
        </div>
        <div className="btn_wrapper">
          {filteredProjects.length > 6 && (
            <button
              type="button"
              className="projects_button"
              onClick={toggleShowAllProjects}
            >
              {showAllProjects ? 'Show Less' : 'Show More'}
              <span className="arrow">
                <BiRightArrowAlt />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
OurProjects.propTypes = {
  locale: PropTypes.string.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    portfolioTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    buttonShowMore: PropTypes.string.isRequired,
    buttonShowLess: PropTypes.string.isRequired,
    goToProject: PropTypes.string.isRequired,
    imageCard: PropTypes.arrayOf(
      PropTypes.shape({
        location: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        image: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }),
    ),
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    localizedPaths: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
        href: PropTypes.string,
      }),
    ).isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
};

export default OurProjects;
